<template>
    <div class="mt-5">
        <div class="addition">
            <span class="hint me-2">{{ $t('dialogs.minimum_payment') }}:</span> {{ minPayment }}{{ currency }}
        </div>
        <div class="mt-2">
            <span class="hint me-2">{{ $t('dialogs.receive_payment') }}:</span> {{ daysReceive }} {{ $tc('dialogs.days', maxDaysReceive) }}
        </div>
        <div class="mt-2">
            <span class="hint me-2">{{ $t('dialogs.check_out_more') }}:</span> <a href="https://www.yoursafe.com/en/index.html?_=1" target="_blank" class="anchor-link"> YourSafe.com</a>
        </div>
        <v-row align="center" class="mt-4 ml-0">
            <v-checkbox hide-details dense class="mt-0 " color="orange darken-3"
                        value="default" v-model="defaultCheck" @change="$emit('change')"/>
            <span class="me-2">{{ $t('dialogs.use_default') }}</span>
        </v-row>
        <v-row class="pl-5 pr-5 mt-8" justify="center">
            <gradient-button  @click="$emit('done')" :loading="loading"  block>{{ $t('dialogs.done') }}</gradient-button>
        </v-row>
    </div>
</template>

<script>
import GradientButton from "@/components/app/button/GradientButton";
import {CURRENCY_SYMBOL} from "../../configs/constants";
export default {
    name: "BottomControlBlock",
    model: {
        prop: 'checked',
        event: 'change'
    },
    data(){
        return {
            currency: CURRENCY_SYMBOL,
            defaultCheck: this.checked === true?'default':null
        }
    },
    props: {
        minPayment: {
            type: [String, Number],
            required: true
        },
        daysReceive: {
            type: [String, Number],
            required: true
        },
        maxDaysReceive: {
            type: String,
            required: true
        },
        checked: {
            type: [Boolean],
            default: false
        },
        loading: Boolean

    },
    components: {GradientButton}
}
</script>

<style scoped>
.hint {
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.4);
}
</style>
